<template>
    <svg viewBox="0 0 418 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_0_1)">
            <path
                d="M67.9675 50.1526C67.8328 50.1024 67.6865 50.0499 67.5281 49.9949C60.9934 47.7277 56.7988 39.7229 55.7998 29.4049C56.4036 16.88 60.5886 5.06496 63.1521 0C63.9284 1.02715 64.5656 1.99697 65.2523 3.04221C67.5924 6.60426 70.508 11.042 81.466 21.6081C89.4433 29.2997 93.7998 41.3782 93.7998 47.6406V60.8C86.076 54.8698 76.2801 51.9933 67.9675 50.1526Z"
                fill="#DBD3AD" />
            <path
                d="M123.59 65.1555C124.492 66.1312 126.906 67.0746 128 67.4243C126.714 68.0227 123.783 69.3664 122.345 69.9558C120.548 70.6925 119.214 71.823 116.293 80.0861C112.321 91.3213 105.393 96.7904 96.5554 102.98C87.4533 109.356 72.1436 112.768 66.0908 113.413L47.933 115.254C32.6165 120.142 18.6506 116.847 14.5212 114.81C47.245 102.21 53.4431 95.0355 58.0831 89.6656C58.2428 89.4806 58.4005 89.2979 58.5577 89.1171C32.1727 83.7277 12.792 52.7936 6.3999 38C25.6258 46.9222 49.8482 52.3475 59.7871 53.9878C59.9977 54.0256 60.2101 54.0634 60.4242 54.1014L60.4572 54.1072C70.8383 55.9536 85.1916 58.5069 95.0597 66.2992C95.3087 66.657 95.7647 66.9386 96.5554 66.9386C97.9788 66.9386 100.691 63.5306 102.465 61.3021C102.715 60.9878 102.947 60.697 103.154 60.4426C106.508 56.3101 110.713 56.1357 113.087 56.1357C118.181 56.1357 121.118 61.1072 122.668 63.7328C123.06 64.3958 123.363 64.9094 123.59 65.1555Z"
                fill="#B79F5E" />
        </g>
        <g clip-path="url(#clip1_0_1)">
            <path
                d="M204.296 87.9278C204.296 88.948 203.887 90.4772 203.479 91.9074C201.847 97.1091 202.562 105.884 195.827 105.884C193.074 105.884 188.583 105.068 178.687 105.068H152.163C150.327 105.068 149 104.559 149 103.129C149 100.885 152.569 101.497 154.305 97.4161C155.121 95.5799 155.428 92.2144 155.428 87.5198V46.5062C155.428 40.2823 155.325 38.4461 152.571 36.8139C151.244 35.9977 149.511 35.6927 149.511 34.0605C149.511 33.0403 150.531 32.2242 152.266 32.2242H178.792C188.381 32.2242 193.789 31.5111 196.747 31.5111C198.686 31.5111 200.726 31.9192 200.827 34.3675C201.031 37.4279 201.336 45.387 201.336 48.3444C201.336 50.1807 200.52 51.3038 198.379 51.3038C197.258 51.3038 196.338 50.6917 195.419 48.3444C194.195 45.387 193.379 42.2236 189.81 39.8763C186.341 37.6319 180.117 37.4279 171.853 37.4279H167.874C166.138 37.4279 165.629 38.141 165.629 39.5712V61.8122C165.629 63.6485 166.14 64.0565 167.977 64.0565C170.425 64.0565 173.893 64.0565 177.974 63.8525C181.749 63.6485 186.034 63.0364 188.686 63.0364C190.727 63.0364 192.359 63.2404 192.359 65.0767C192.359 65.8928 192.155 66.4039 192.054 67.22C191.747 69.8723 190.626 70.3834 187.054 70.3834C184.503 70.3834 181.137 70.0764 177.362 69.9753C174.2 69.8723 169.71 69.8723 166.956 69.8723C166.037 69.8723 165.629 70.3834 165.629 71.1995V88.9519C165.629 94.8687 165.732 97.216 167.261 98.4402C168.589 99.4603 171.241 99.7654 174.71 99.7654C181.646 99.7654 186.646 98.9493 189.911 97.01C196.542 93.1335 197.359 85.1764 201.439 85.0734C202.97 85.0734 204.296 86.1946 204.296 87.9298V87.9278Z"
                fill="currentColor" />
            <path
                d="M277.031 97.008C278.663 101.396 282.539 100.784 282.539 103.129C282.539 104.559 281.212 105.068 279.378 105.068H262.646C260.81 105.068 259.482 104.559 259.482 103.129C259.482 100.885 262.951 101.497 264.787 97.5191C265.704 95.4788 265.908 92.3154 265.908 87.5217V70.2804C265.908 69.5673 265.5 69.1592 264.684 69.1592H230.71C229.997 69.1592 229.793 69.3632 229.793 70.0764V87.5217C229.793 92.5214 229.997 95.9899 231.017 97.9272C232.854 101.499 236.118 100.885 236.118 103.129C236.118 104.559 234.791 105.068 232.955 105.068H216.325C214.386 105.068 213.061 104.559 213.061 103.129C213.061 100.885 216.734 101.598 218.469 97.2121C219.285 95.2728 219.489 92.2124 219.489 87.5198V47.8333C219.489 40.3853 219.285 38.345 216.938 36.8138C215.202 35.6927 213.673 35.5897 213.673 33.9575C213.673 32.8363 214.693 32.2222 216.427 32.2222H232.852C234.587 32.2222 235.607 32.8343 235.607 33.9575C235.607 35.5897 233.975 35.6927 232.343 36.8138C230.098 38.345 229.791 40.3853 229.791 47.8333V62.7274C229.791 63.4425 230.199 63.8505 231.015 63.8505H264.989C265.601 63.8505 265.906 63.5435 265.906 62.9314V47.8333C265.906 40.3853 265.702 38.345 263.254 36.8138C261.622 35.7937 260.09 35.5897 260.09 33.9575C260.09 32.8363 261.008 32.2222 262.846 32.2222H279.17C281.006 32.2222 281.923 32.8343 282.026 33.9575C282.026 35.5897 280.394 35.6927 278.762 36.8138C276.415 38.345 276.11 40.3853 276.11 47.8333V87.5198C276.11 91.9073 276.314 95.0688 277.027 97.008H277.031Z"
                fill="currentColor" />
            <path
                d="M339.051 45.9971C339.051 48.6495 338.744 51.8109 336.195 51.8109C333.95 51.8109 333.237 49.3626 331.502 46.8113C328.953 43.0358 324.666 36.3028 316.2 36.3028C307.734 36.3028 303.447 42.1186 303.447 47.7304C303.447 66.2989 341.909 57.5237 341.909 82.7222C341.909 93.1276 333.136 106.187 314.263 106.187C305.182 106.187 296.102 103.23 293.246 98.1273C292.022 95.986 291.511 93.6387 291.41 91.3944C291.307 89.15 291.103 87.5178 291.103 85.6816C291.103 83.6413 292.327 82.7222 293.654 82.7222C296.306 82.7222 296.714 85.7826 300.591 90.7823C303.752 94.8648 308.956 100.475 317.22 100.475C327.626 100.475 332.625 93.4366 332.625 87.0068C332.625 66.602 294.163 76.2943 294.163 51.912C294.163 40.8925 303.752 30.9981 319.26 30.9981C326.403 30.9981 333.338 33.0384 336.298 36.3028C338.338 38.5471 339.053 42.7307 339.053 45.9952L339.051 45.9971Z"
                fill="currentColor" />
            <path
                d="M417.806 33.9575C417.806 35.7937 415.664 35.9978 413.521 38.038C411.582 39.8743 409.951 42.3226 406.891 48.6475C404.443 53.7482 381.897 104.862 381.897 104.862C381.59 105.474 381.182 105.882 380.466 105.882H376.998C376.285 105.882 375.877 105.577 375.57 104.862L353.329 46.8112C351.593 42.1186 350.371 39.3632 348.636 37.834C346.495 35.8948 344.147 35.9978 344.147 34.0585C344.147 32.8343 345.065 32.2223 347.311 32.2223H363.94C365.572 32.2223 366.491 32.9373 366.491 34.0585C366.491 36.3028 362.001 36.0988 362.001 39.3632C362.001 40.6904 362.716 42.6277 364.041 46.1992L381.283 91.1903C381.487 91.7014 381.588 91.9054 381.895 91.9054C382.099 91.9054 382.2 91.7014 382.507 90.9863C382.507 90.9863 396.383 58.3398 397.912 54.5643C400.36 48.5445 402.095 43.9549 402.095 40.8925C402.095 35.9958 397.3 36.6079 397.3 34.0565C397.3 32.7294 398.524 32.2203 400.259 32.2203H414.745C416.684 32.2203 417.806 32.7313 417.806 33.9555V33.9575Z"
                fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="128" height="128" fill="white" />
            </clipPath>
            <clipPath id="clip1_0_1">
                <rect width="269" height="75.2725" fill="white" transform="translate(149 31)" />
            </clipPath>
        </defs>
    </svg>
</template>