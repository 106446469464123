<script setup lang="ts">
const items = [
    {
        label: 'Inicio',
        to: '/',
    },
    {
        label: 'Podcast',
        to: '/podcast',
    },
    {
        label: 'Libros',
        to: '/libros-biblicos',
    },
    {
        label: 'Acerca de',
        to: '/acerca',
    },
    {
        label: 'Donaciones',
        to: '/donaciones',
    },
    {
        label: 'Contacto',
        to: '/contacto',
    }
]

const open = ref(false)

const openSearchInModal = ref(false)

const { isLoading } = useLoadingIndicator()
</script>

<template>
    <TopBar
        class="top-0 px-4 sm:px-6 sticky z-20 print:hidden"
    >
        <div v-if="isLoading" class="-mx-4 sm:-mx-6 absolute w-full top-0">
            <UProgress
                :ui="{
                    base: 'rounded-none',
                    indicator: 'rounded-none bg-[var(--ui-color-primary-400)]',
                }"
                size="sm"
            />
        </div>
        <div class="w-full max-w-7xl mx-auto flex py-4 sm:py-4" data-nosnippet>
            <div class="grow basis-0">
                <NuxtLink aria-label="Ir a la página de inicio de En Honor a Su Verdad" class="flex items-center gap-1 text-[var(--ui-color-primary-500)] dark:text-[var(--ui-color-primary-200)] w-fit" to="/">
                    <BrandIsologotype class="h-10 -translate-y-[4.5%]" />
                </NuxtLink>
            </div>
            <nav class="flex items-center justify-center gap-2 grow-0 basis-auto max-lg:hidden font-serif">
                <UButton
                    v-for="item in items"
                    :key="item.label"
                    :to="item.to"
                    :color="$route.path === item.to ? 'primary' : 'neutral'"
                    variant="ghost"
                    size="lg"
                >
                    {{ item.label }}
                </UButton>
            </nav>
            <div class="grow basis-0 flex items-center justify-end gap-2">
                <UButton icon="lucide:search" variant="ghost" color="neutral" size="xl" aria-label="Buscar" @click="openSearchInModal = true" />
                <LazyUModal v-model:open="openSearchInModal">
                    <template #content>
                        <LazySearchUi class="sm:max-h-[60vh]" @close="openSearchInModal = false" />
                    </template>
                </LazyUModal>
                <ColorMode />
                <UDrawer
                    v-model:open="open"
                    :ui="{
                        body: 'flex flex-col gap-2'
                    }"
                >
                    <UButton class="lg:hidden" color="neutral" variant="ghost" size="xl" icon="material-symbols:menu-rounded" aria-label="Menú" />
                    <template #body>
                        <UButton
                            v-for="item in items"
                            :key="item.label"
                            :to="item.to"
                            @click="open = false"
                            color="neutral"
                            variant="ghost"
                            size="xl"
                            block
                        >
                            {{ item.label }}
                        </UButton>
                    </template>
                </UDrawer>
            </div>
        </div>
    </TopBar>
</template>