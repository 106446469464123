<script setup lang="ts">
const colorMode = useColorMode()
const colorModeItems = computed(() => [
    {
        label: 'Claro',
        icon: 'material-symbols:light-mode-outline-rounded',
        value: 'light',
        active: colorMode.preference === 'light',
        onSelect: () => colorMode.preference = 'light',
    },
    {
        label: 'Oscuro',
        icon: 'material-symbols:dark-mode-outline-rounded',
        value: 'dark',
        active: colorMode.preference === 'dark',
        onSelect: () => colorMode.preference = 'dark',
    },
    {
        label: 'Sistema',
        icon: 'material-symbols:desktop-windows-outline-rounded',
        value: 'system',
        active: colorMode.preference === 'system',
        onSelect: () => colorMode.preference = 'system',
    },
])
</script>

<template>
    <ClientOnly>
        <UDropdownMenu :items="colorModeItems" size="xl" arrow>
            <UButton color="neutral" variant="ghost" icon="material-symbols:light-mode-outline-rounded" aria-label="Cambiar modo de color" size="xl">
                <template #leading>
                    <UIcon class="size-6" v-if="colorMode.value === 'light'" name="material-symbols:light-mode-outline-rounded" />
                    <UIcon class="size-6" v-else-if="colorMode.value === 'dark'" name="material-symbols:dark-mode-outline-rounded" />
                </template>
            </UButton>
        </UDropdownMenu>
        <template #fallback>
            <UButton color="neutral" variant="ghost" icon="material-symbols:light-mode-outline-rounded" aria-label="Cambiar modo de color" size="xl">
                <template #leading>
                    <UIcon class="size-6 opacity-0" name="material-symbols:light-mode-outline-rounded" />
                </template>
            </UButton>
        </template>
    </ClientOnly>
</template>