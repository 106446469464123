<script setup lang="ts">
const props = defineProps<{
    elevateOnScroll?: boolean | string
    elevateBackground?: boolean
    elevateBorder?: boolean
    elevateShadow?: boolean
    elevated?: boolean
}>()

const elevate = ref(props.elevateOnScroll ? false : props.elevated)

onMounted(() => {
    if (props.elevateOnScroll) {
        if (typeof props.elevateOnScroll === 'boolean') {
            elevate.value = !!document.scrollingElement?.scrollTop
            document.addEventListener('scroll', () => {
                elevate.value = !!document.scrollingElement?.scrollTop
            })
        } else {
            const scrollingElement = document.querySelector(props.elevateOnScroll) as HTMLElement | null
            if (scrollingElement) {
                elevate.value = !!scrollingElement.scrollTop
                scrollingElement.addEventListener('scroll', () => {
                    elevate.value = !!scrollingElement.scrollTop
                })
            }
        }
    }
})

const classes = computed(() => {
    const classList = [] as string[]
    if (props.elevateBackground) {
        if (elevate.value) {
            classList.push('bg-[var(--ui-bg)]')
        } else {
            classList.push('bg-transparent')
        }
    } else {
        classList.push('bg-[var(--ui-bg)] dark:bg-[var(--ui-bg-elevated)]/50')
    }

    if (props.elevateBorder) {
        if (elevate.value) {
            if (!props.elevateShadow) {
                classList.push('border-[var(--ui-border)] border-b')
            } else {
                classList.push('dark:border-b')
            }
            classList.push('dark:border-[var(--ui-border-accented)]')
        } else {
            classList.push('border-transparent')
        }
    }

    if (props.elevateShadow && elevate.value) {
        classList.push('shadow-md')
    }

    return classList
})
</script>

<template>
    <header class="duration-250" :class="classes">
        <slot :elevate="elevate" />
    </header>
</template>